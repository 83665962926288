import React, { useEffect, useRef } from 'react'
import style from './index.module.less'
import qs from 'querystring'
import { Toast } from 'antd-mobile'
import { awaitWrap } from '@src/assets/js/tool'
import { sendSMS } from './ajax'

async function sendHandle (mobile: string, arr: number[]): Promise<boolean> {
  const [e] = await awaitWrap(sendSMS(mobile, arr))
  if (e !== null) {
    Toast.show({
      content: e.message
    })
  }
  return e === null
}

function Main (): React.ReactElement {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const s = qs.parse(location.search.slice(1))
    if (typeof s.mobile !== 'string') {
      Toast.show({
        content: '手机号不存在',
        icon: 'fail'
      })
      return undefined
    }
    if (!/^1\d{10}$/.test(s.mobile)) {
      Toast.show({
        content: '手机号格式不正确',
        icon: 'fail'
      })
      return undefined
    }
    if (ref.current === null) {
      return undefined
    }
    const c = document.createElement('div')
    c.setAttribute('id', 'captcha')
    ref.current.appendChild(c)
    window.sliderCaptcha({
      id: 'captcha',
      width: 280,
      height: 150,
      sliderL: 42,
      sliderR: 12,
      offset: 8,
      loadingText: '正在加载中...',
      failedText: '再试一次',
      barText: '向右滑动填充拼图',
      repeatIcon: 'fa fa-redo',
      remoteUrl: '/api/common/smsCaptcha',
      setSrc: function () {
        return `/slidercaptcha/images/Pic${Math.floor(Math.random() * 4)}.jpg`
      },
      verify: async (arr: number[], spliced: boolean) => {
        if (!spliced) {
          return await Promise.reject(new Error('验证失败'))
        }
        const res = await sendHandle(s.mobile as string, arr)
        if (res) {
          Toast.show({
            content: '短信发送成功',
            icon: 'success'
          })
          if (wx !== undefined) {
            if (wx.miniProgram !== undefined) {
              if (wx.miniProgram.postMessage !== undefined) {
                wx.miniProgram.postMessage({ data: { time: Date.now() } })
              }
              if (wx.miniProgram.navigateBack !== undefined) {
                wx.miniProgram.navigateBack()
              }
            }
          }
          try {
            window.ReactNativeWebView.postMessage('success')
          } catch (e) {
            console.error(e)
          }
        } else {
          return await Promise.reject(new Error('验证失败'))
        }
      }
    })
    return () => {
      document.body.removeChild(c)
    }
  }, [ref])
  useEffect(() => {
    const title = document.title
    document.title = '安全验证'
    return () => {
      document.title = title
    }
  }, [])
  return (
    <div className={style.container}>
      <div className={style.body} ref={ref}>
      </div>
    </div>
  )
}

export default Main
