import React from 'react'
import { Result } from 'antd-mobile'
import style from './index.module.less'

function Main (): React.ReactElement {
  return (
    <div className={style.container}>
      <Result
        status='warning'
        title="404"
        description="页面不存在"
      />
    </div>
  )
}

export default Main
