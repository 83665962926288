import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Router from '@router/index'
import store from '@redux/index'
import reportWebVitals from './reportWebVitals'
import './index.less'

// 根据屏幕宽度自动调整根字体大小
(function () {
  const d: HTMLElement = document.documentElement
  const w: number = d.offsetWidth
  const fontSize: number = w / 375 * 20
  d.style.fontSize = `${fontSize}px`
})()

ReactDOM.render(
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <Router />
  //   </Provider>
  // </React.StrictMode>,
  <Provider store={store}>
    <Router />
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
