import React, { ReactElement } from 'react'
import {
  Route,
  Switch,
  withRouter,
  Router,
  Redirect
} from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './router.less'
import { RouterConfig } from './routerConfig'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
export const history = createBrowserHistory()

const ANIMATION_MAP = {
  PUSH: 'forward',
  POP: 'back',
  REPLACE: 'back'
}

const Routes = withRouter(({ location, history }) => {
  return (
    <TransitionGroup
      className='router-wrapper'
      childFactory={child => React.cloneElement(
        child,
        { classNames: ANIMATION_MAP[history.action] }
      )}
    >
      <CSSTransition
        timeout={500}
        key={location.pathname}
      >
        <Switch location={location}>
          {RouterConfig.map((config, index) => (
            <Route exact key={index} {...config} />
          ))}
          <Route path='/*' render={() => <Redirect to={('/no-found')} />} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
})

export default (): ReactElement => {
  return (
    <Router history={history}>
      <Routes />
    </Router>
  )
}
