import { $freePost } from '@js/request'
import { compress } from '@js/tool'

export async function sendSMS (mobile: string, captchaAtt: number[]): Promise<void> {
  const res = await $freePost('/api/common/smsCaptcha', {
    template_code: 'SMS_182920835',
    mobile: compress(mobile),
    captchaAtt
  })
  return res.data
}
