import React from 'react'
import { Result } from 'antd-mobile'
import style from './index.module.less'

function Main (): React.ReactElement {
  return (
    <div className={style.container}>
      <Result
        status='success'
        title="版本"
        description="1.0.0"
      />
    </div>
  )
}

export default Main
