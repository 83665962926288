// import React from 'react'
import NoFound from '@src/pages/noFound'
import FileSign from '@src/pages/fileSign'
import FileConfirm from '@src/pages/fileConfirm'
import Version from '@src/pages/version'
import SendSMSValidate from '@src/pages/sendSmsValidate'
import Media from '@src/pages/Media'
// import { Redirect } from 'react-router-dom'
import { routerConfigs } from './registerRouter'

export const RouterConfig = [
  {
    path: '/version',
    component: Version
  },
  {
    path: '/send-sms-validate',
    component: SendSMSValidate
  },
  {
    path: '/no-found',
    component: NoFound
  },
  {
    path: '/file-sign',
    component: FileSign
  },
  {
    path: '/file-confirm',
    component: FileConfirm
  },
  {
    path: '/c',
    component: Media
  },
  ...routerConfigs
]
