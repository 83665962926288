export const rootDir = '/doc'
export const baseHost = 'https://zcexapp.zhichu.tech'

export const pk = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1IktOwr+4VSXGUmYfaqsfe1dT
VPKOW/LYQUHF7VBB75Pa5gMl+wI9CW2Y7th9F/9MFokQINzoZg1bsR157XOdO8bE
Vr00HBpFCtyHxw9jqWoaivDJlxsmYXdC24X46mWg8/E6LWGOXx98cTQ3k/K67NwD
r6JaRBJp1tkVcO4pkwIDAQAB
-----END PUBLIC KEY-----`

// 返回完整地址
export function fullPath (path: string): string {
  if (/^\//.test(path)) {
    return `/doc${path}`
  }
  return `/doc/${path}`
}
