import NodeRSA from 'node-rsa'
import { JSEncrypt } from 'jsencrypt'
import { pk } from './config'
import dayjs from 'dayjs'

// async函数错误处理方法
export async function awaitWrap<T, U = any> (promise: Promise<T>): Promise<[U | null, T | null]> {
  return await promise
    .then<[null, T]>((data: T) => [null, data])
    .catch<[U, null]>(err => [err, null])
}

// 公钥加密
export function compress (v: string): string {
  // const key = new NodeRSA(pk, undefined, { encryptionScheme: 'pkcs1' })
  // const str = key.encrypt(v, 'base64')
  try {
    const encryptTool = new JSEncrypt()
    encryptTool.setPublicKey(pk)
    const str = encryptTool.encrypt(v)
    if (typeof str === 'string') {
      return str
    }
  } catch (e) {
    console.error(e)
  }
  return ''
}

// 公钥解密
export function decompress (v: string): string {
  try {
    if (typeof v === 'string' && v !== '') {
      const key = new NodeRSA()
      key.importKey(pk)
      const str = key.decryptPublic(v, 'utf8')
      if (typeof str === 'string') {
        return str
      }
    }
  } catch (e) {
    console.error(e)
  }
  return ''
}

export function formatDate (t: number, format: string = 'YYYY-MM-DD HH:mm'): string {
  if (typeof t === 'number') {
    return dayjs(t * 1000).format(format)
  }
  return '无'
}
