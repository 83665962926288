import { ReactElement, useRef, useEffect, useState } from 'react'
import { Button, Space, Toast, CenterPopup } from 'antd-mobile'
import style from './index.module.less'
import SignaturePad from 'signature_pad'
import { requestOneFile } from '@js/request'
import { awaitWrap } from '@src/assets/js/tool'
import classNames from 'classnames'
import { signRequest, validateRequest } from './ajax'

let timer: NodeJS.Timeout

function dataURLtoFile (dataurl: string, filename: string): File { // 将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
  const arr = dataurl.split(',')
  const sArr: RegExpMatchArray = arr[0].match(/:(.*?);/) as RegExpMatchArray
  const mime = sArr[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n-- > 0) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

async function uploadSign (str: string, setFileid: (v: string) => void, setSubmiting: (v: boolean) => void): Promise<void> {
  const f = dataURLtoFile(str, '签名')
  Toast.show({ icon: 'loading', content: '上传中', duration: 120 * 1000 })
  setSubmiting(true)
  const [e, d] = await awaitWrap(requestOneFile(f))
  setSubmiting(false)
  if (e === null) {
    Toast.clear()
  }
  if (d !== null) {
    setFileid(d.data.id)
  }
}

function decideHandle (sign: SignaturePad | null, width: number, height: number, submitable: boolean, setFileid: (v: string) => void, setSubmiting: (v: boolean) => void): void {
  if (!submitable) {
    Toast.show({
      content: '签名不能为空',
      icon: 'fail'
    })
    return undefined
  }
  if (sign === null) {
    return undefined
  }
  const img = document.createElement('img')
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  img.setAttribute('style', 'display: none;')
  canvas.setAttribute('style', 'display: none;')
  img.src = sign.toDataURL()
  img.onload = () => {
    ctx?.rotate(-90 * Math.PI / 180)
    ctx?.drawImage(img, -width, 0, width, height)
    uploadSign(canvas.toDataURL(), setFileid, setSubmiting).catch(e => console.error(e))
    document.body.removeChild(canvas)
    document.body.removeChild(img)
  }
  canvas.width = height
  canvas.height = width
  document.body.appendChild(canvas)
  document.body.appendChild(img)
}

function iosInput (): void {
  // 获取目前所在位置
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const sh = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  // 回到原位置
  setTimeout(() => {
    window.scrollTo(0, sh)
  }, 50)
}

async function caHandle (id: string, fileid: string, setTime: (v: number) => void, setVisible: (v: boolean) => void, setCode: (v: string) => void): Promise<void> {
  Toast.show({ content: '提交中', icon: 'loading', duration: 120 * 1000 })
  const [e, d] = await awaitWrap(signRequest(id, fileid))
  if (e === null) {
    Toast.clear()
  }
  if (e === null && d !== null) {
    setTime(Date.now())
    setVisible(true)
    setCode('')
  }
}

function loopTime (time: number, setSecond: (v: number) => void): void {
  const t = 60 - Math.floor((Date.now() - time) / 1000)
  if (timer !== undefined && timer !== null) {
    clearTimeout(timer)
  }
  if (t > 0) {
    setSecond(t)
    timer = setTimeout(() => {
      loopTime(time, setSecond)
    }, 1000)
  } else {
    setSecond(0)
  }
}

async function validateHandle (caid: string, code: string, caseid: string, key: string, title: string, id: string): Promise<void> {
  Toast.show({ content: '提交中', icon: 'loading', duration: 120 * 1000 })
  const [e] = await awaitWrap(validateRequest(caid, code))
  if (e === null) {
    Toast.show({
      content: '签署成功',
      icon: 'success'
    })
    setTimeout(() => {
      try {
        wx.miniProgram.navigateBack()
        setTimeout(() => {
          location.reload()
        }, 500)
      } catch (e) {
        Toast.show({
          content: '返回失败',
          icon: 'fail'
        })
      }
    }, 1000)
  }
}

function getMobile (m: string): string {
  return m.slice(0, 3) + '****' + m.slice(7)
}

interface Props {
  id: string
  hide: () => void
  mobile: string
  caseid: string
  k: string
  title: string
  caid: string
}

function Main ({ id, caid, hide, mobile, caseid, k, title }: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [sign, setSign] = useState<SignaturePad | null>(null)
  const [fileid, setFileid] = useState('')
  const [visible, setVisible] = useState(false)
  const [second, setSecond] = useState(0)
  const [time, setTime] = useState(0)
  const [submitable, setSubmitable] = useState(false)
  const [code, setCode] = useState('')
  const [submiting, setSubmiting] = useState(false)
  useEffect(() => {
    if (ref.current !== null) {
      setWidth(ref.current.offsetWidth)
      setHeight(ref.current.offsetHeight)
      const canvas: HTMLCanvasElement = document.querySelector('#canvas') as HTMLCanvasElement
      if (canvas !== null) {
        setSign(new SignaturePad(canvas, { maxWidth: 5, minWidth: 2 }))
      }
    }
  }, [ref])
  useEffect(() => {
    if (fileid.length > 0) {
      caHandle(caid, fileid, setTime, setVisible, setCode).catch(e => console.error(e))
    }
  }, [fileid])
  useEffect(() => {
    if (time !== 0) {
      loopTime(time, setSecond)
    }
  }, [time])
  useEffect(() => {
    if (visible) {
      iosInput()
    }
  }, [visible])
  return (
    <div className={style.container}>
      <CenterPopup visible={visible}>
        <div className={style.modal}>
          <div className={style['modal-description']}>接收号码为：{getMobile(mobile)}</div>
          <div className={style['modal-input-line']}>
            <div className={style['modal-input-left']}>
              <input className={style['modal-input']} type='tel' maxLength={6} placeholder='请输入' value={code} onChange={(e) => setCode(e.target.value)}onBlur={() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0 }} />
            </div>
            <div className={style['modal-input-right']}>
              <button className={classNames(style['modal-sms-btn'], { [style.disabled]: second > 0 })} onClick={() => {
                if (second === 0) {
                  caHandle(caid, fileid, setTime, setVisible, setCode).catch(e => console.error(e))
                }
              }}>
                {second > 0 ? `${second} 秒` : '重新发送'}
              </button>
            </div>
          </div>
          <div className={style['modal-btns']}>
            <Space direction='horizontal'>
              <Button onClick={() => {
                setVisible(false)
              }}>取消</Button>
              <Button color='primary' onClick={() => {
                validateHandle(caid, code, caseid, k, title, id).catch(e => console.error(e))
              }}>确定</Button>
            </Space>
          </div>
        </div>
      </CenterPopup>
      <div className={style.canvas} ref={ref}>
        <canvas id='canvas' width={width} height={height} onTouchEnd={() => setSubmitable(true)} />
      </div>
      <div className={style.btn}>
        <div className={style['btn-inner']}>
          <Button onClick={() => hide()}>取消</Button>
          <Space style={{ '--gap': '1rem' }}>
            <Button onClick={() => {
              setSubmitable(false)
              if (sign !== null) {
                sign.clear()
              }
            }}>清空</Button>
            <Button color='primary' onClick={() => decideHandle(sign, width, height, submitable, setFileid, setSubmiting)}>确认</Button>
          </Space>
        </div>
      </div>
      {submiting && <div className={style.mask} />}
    </div>
  )
}

export default Main
