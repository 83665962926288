import { $freeGet, $freePut } from '@js/request'
import { OperateDocShcema } from '../../components/schema/operateSchema'

export async function confirmRequest (uid: string, token: string, id: string, status: string): Promise<any> {
  const data: {[k: string]: any} = {
    status,
    uid
  }
  const res = await $freePut(`/api/arbitrator/documentExchange/confirminfo/${id}`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}

export async function getInfoRequest (uid: string, token: string, id: string): Promise<OperateDocShcema> {
  const res = await $freeGet(`/api/arbitrator/documentExchange/${id}`, {
    uid
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}
