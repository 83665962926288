import { $put } from '@js/request'

export async function signRequest (id: string, fileid: string): Promise<void> {
  const res = await $put(`/api/arbitrator/document/filesign/${id}`, {
    sign_fileid: fileid
  })
  return res.data
}

export async function validateRequest (id: string, code: string): Promise<void> {
  const res = await $put(`/api/arbitrator/document/filesignto/${id}/${code}`)
  return res.data
}
