import { Action } from 'redux'
import BASIC from '../actionTypes/basic'

export interface USERINFO {
  app_default: string
  desktop: string
  employeeid: string
  id: string
  is_auth: number
  mobile: string
  realname: string
  role_default: string
}

export interface SET_TOKEN_ACTION extends Action {
  type: BASIC
  token: NonNullable<string>
}

export interface SET_USERINFO_ACTION extends Action {
  type: BASIC
  userInfo: NonNullable<USERINFO>
}

export type BASIC_ACTION = SET_TOKEN_ACTION | SET_USERINFO_ACTION

export const setToken = (token: NonNullable<string>): SET_TOKEN_ACTION => ({
  type: BASIC.SET_TOKEN,
  token
})

export const setUserInfo = (userInfo: NonNullable<USERINFO>): SET_USERINFO_ACTION => ({
  type: BASIC.SET_USER_INFO,
  userInfo
})
