import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import qs from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { setToken, setUserInfo } from '@src/redux/actions/basic'
import { Document, Page } from 'react-pdf'
import { SpinLoading, Button, Toast, Dialog } from 'antd-mobile'
import style from './index.module.less'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { useLocation } from 'react-router-dom'
import { awaitWrap } from '@src/assets/js/tool'
import { confirmRequest, getInfoRequest } from './ajax'
import { OperateDocShcema } from '@src/components/schema/operateSchema'

const oMap = { uid: '', token: '' }

async function initData (id: string, pages: number, setCaseid: (v: string) => void, setData: (v: OperateDocShcema) => void): Promise<void> {
  Toast.show({ content: '加载中', icon: 'loading', duration: 120 * 1000 })
  const [e, d] = await awaitWrap(getInfoRequest(oMap.uid, oMap.token, id))
  Toast.clear()
  if (e === null) {
    if (d !== null) {
      if (pages === 1 && d.status === '已确认') {
        wx.miniProgram.redirectTo({ url: `/packageA/pages/CaseOperate/index?id=${d.caseid}` })
      } else {
        setCaseid(d.caseid)
        setData(d)
      }
    }
  }
}

async function confirmHandle (id: string, caseid: string, pages: number, status: string): Promise<void> {
  Toast.show({ content: '确认中', icon: 'loading', duration: 120 * 1000 })
  const [e] = await awaitWrap(confirmRequest(oMap.uid, oMap.token, id, status))
  if (e === null) {
    Toast.clear()
    Toast.show({
      content: '确认成功',
      icon: 'success',
      duration: 1000
    })
    setTimeout(() => {
      setTimeout(() => {
        location.reload()
      }, 500)
      try {
        if (pages === 1) {
          wx.miniProgram.redirectTo({ url: `/packageA/pages/CaseOperate/index?id=${caseid}` })
        } else {
          wx.miniProgram.navigateBack()
        }
      } catch (e) {
        Toast.show({
          content: '返回失败',
          icon: 'fail'
        })
      }
    }, 1000)
  }
}

function renderPages (
  ready: boolean,
  numPages: number,
  width: number,
  height: number
): ReactNode {
  if (ready) {
    const arr = []
    for (let i = 0; i < numPages; i++) {
      arr.push((
        <div className={style.page} key={i}>
          <Page pageNumber={i + 1} width={width} height={height} renderTextLayer={false} renderAnnotationLayer={false} />
        </div>
      ))
    }
    return arr
  }
}

// 初始化文件参数
function initDoc (
  o: {[k: string]: any},
  setNums: (v: number) => void,
  setReady: (v: boolean) => void,
  setWidth: (v: number) => void,
  setHeight: (v: number) => void
): void {
  const width = window.document.documentElement.offsetWidth
  setNums(o.numPages)
  setReady(true)
  setWidth(width)
  setHeight(width * 1.4140625)
}

function Main (): ReactElement {
  const dispatch = useDispatch()
  const l = useLocation()
  const [url, setUrl] = useState('')
  const [id, setId] = useState('')
  const [nums, setNums] = useState(0)
  const [ready, setReady] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [caseid, setCaseid] = useState('')
  const [pages, setPages] = useState(1)
  const [data, setData] = useState<OperateDocShcema | null>(null)
  const [r, setR] = useState(false)
  const { userInfo } = useSelector((state: { basic: BASIC_STATE }) => {
    return {
      userInfo: state.basic.userInfo
    }
  })
  const status = useMemo(() => {
    const i = userInfo.id
    if (data !== null && typeof i === 'string' && i.length > 0) {
      const l = data.document !== null && data.document.document_participant instanceof Array ? data.document.document_participant : []
      const o = l.find(li => li.accountid === i)
      if (o !== undefined) {
        return o.status
      }
    }
    return ''
  }, [userInfo, data])
  useEffect(() => {
    setTimeout(() => {
      const params = qs.parse(l.search)
      if (typeof params.token === 'string' && params.token.length > 0) {
        dispatch(setToken(params.token))
        oMap.token = params.token
      }
      if (typeof params.pages === 'string' && params.pages.length > 0) {
        setPages(parseInt(params.pages))
      }
      if (typeof params.url === 'string' && params.url.length > 0) {
        setUrl(params.url)
      }
      if (typeof params.id === 'string' && params.id.length > 0) {
        setId(params.id)
      }
      if (typeof params.uid === 'string' && params.uid.length > 0) {
        dispatch(setUserInfo({ ...userInfo, id: params.uid }))
        oMap.uid = params.uid
        setR(true)
      }
    }, 0)
  }, [l])
  useEffect(() => {
    if (id !== '' && userInfo.id !== '' && r) {
      initData(id, pages, setCaseid, setData).catch(e => console.error(e))
    }
  }, [id, userInfo, r])
  return (
    <div className={style.container}>
      <div className={style.pdf}>
        {url !== '' && (
          <Document
            loading={(
              <div className={style.loading}>
                <div className={style['loading-inner']}>
                  <SpinLoading style={{ '--size': '48px' }} />
                  <span className={style['loading-text']}>加载中</span>
                </div>
              </div>
            )}
            file={url}
            onLoadSuccess={e => initDoc(e, setNums, setReady, setWidth, setHeight)}
            error='文书生成失败，请点击“重新生成”操作。'
            // options={{ cMapUrl: 'cmaps/', cMapPacked: true }}
          >
            {renderPages(ready, nums, width, height)}
          </Document>
        )}
      </div>
      {
        ready && status === '已提交' && (
          <div className={style.btn}>
            <Button block color='primary' onClick={() => {
              Dialog.confirm({
                content: '是否同意材料无误？',
                onConfirm: async () => {
                  await confirmHandle(id, caseid, pages, '已确认')
                }
              }).catch(e => console.error(e))
            }}>同意</Button>
            <Button block className={style.reject} onClick={() => {
              wx.miniProgram.redirectTo({ url: `/packageA/pages/CaseConfirmReject/index?id=${id}&caseid=${caseid}` })
            }}>不同意</Button>
          </div>
        )
      }
       {
        data !== null && status === '已确认' && (
          <div className={style.btn}>
            <Button block disabled>已同意</Button>
          </div>
        )
      }
      {
        data !== null && status === '审核不通过' && (
          <div className={style.btn}>
            <Button block disabled>已不同意</Button>
          </div>
        )
      }
    </div>
  )
}

export default Main
