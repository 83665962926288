import React from 'react'
export interface RouterConfig {
  path: string
  component: React.FC<any>
}
export let routerConfigs: RouterConfig[] = []
// 替换并合并路由
export function registerRouter (otherConfig: RouterConfig[]): void {
  const paths: string[] = otherConfig.map(li => li.path)
  const newRouters: RouterConfig[] = []
  routerConfigs.forEach(li => {
    // 过滤原路由中的同path路由，确保path的唯一性
    if (!paths.includes(li.path)) {
      newRouters.push(li)
    }
  })
  routerConfigs = [...newRouters, ...otherConfig]
}
