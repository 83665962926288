import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import qs from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { setToken, setUserInfo } from '@src/redux/actions/basic'
import { Document, Page } from 'react-pdf'
import { SpinLoading, Button, Toast } from 'antd-mobile'
import style from './index.module.less'
import { CSSTransition } from 'react-transition-group'
import Sign from '@component/sign'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { useLocation } from 'react-router-dom'
import { awaitWrap } from '@src/assets/js/tool'
import { getDataRequest } from './ajax'
import { OperateDocShcema } from '@component/schema/operateSchema'

const oMap = { uid: '', token: '' }

async function initData (id: string, setData: (v: OperateDocShcema) => void): Promise<void> {
  Toast.show({
    icon: 'loading',
    content: '加载中',
    duration: 60 * 1000
  })
  const [e, d] = await awaitWrap(getDataRequest(oMap.uid, oMap.token, id))
  if (e === null) {
    Toast.clear()
  }
  if (e === null && d !== null) {
    setData(d)
  }
}

function renderPages (
  ready: boolean,
  numPages: number,
  width: number,
  height: number
): ReactNode {
  if (ready) {
    const arr = []
    for (let i = 0; i < numPages; i++) {
      arr.push((
        <div className={style.page} key={i}>
          <Page pageNumber={i + 1} width={width} height={height} renderTextLayer={false} renderAnnotationLayer={false} />
        </div>
      ))
    }
    return arr
  }
}

// 初始化文件参数
function initDoc (
  o: {[k: string]: any},
  setNums: (v: number) => void,
  setReady: (v: boolean) => void,
  setWidth: (v: number) => void,
  setHeight: (v: number) => void
): void {
  const width = window.document.documentElement.offsetWidth
  setNums(o.numPages)
  setReady(true)
  setWidth(width)
  setHeight(width * 1.4140625)
}

function Main (): ReactElement {
  const dispatch = useDispatch()
  const l = useLocation()
  const [url, setUrl] = useState('')
  const [id, setId] = useState('')
  const [caid, setCaid] = useState('')
  const [caseid, setCaseid] = useState('')
  const [mobile, setMobile] = useState('')
  const [key, setKey] = useState('')
  const [title, setTitle] = useState('')
  const [nums, setNums] = useState(0)
  const [ready, setReady] = useState(false)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<OperateDocShcema | null>(null)
  const [r, setR] = useState(false)
  const { userInfo } = useSelector((state: { basic: BASIC_STATE }) => {
    return {
      userInfo: state.basic.userInfo
    }
  })
  const status = useMemo(() => {
    const i = userInfo.id
    if (data !== null && typeof i === 'string' && i.length > 0) {
      const l = data.document !== null && data.document.document_participant instanceof Array ? data.document.document_participant : []
      const o = l.find(li => li.accountid === i)
      if (o !== undefined) {
        return o.status
      }
    }
    return ''
  }, [userInfo, data])
  useEffect(() => {
    setTimeout(() => {
      const params = qs.parse(l.search)
      if (typeof params.token === 'string' && params.token.length > 0) {
        dispatch(setToken(params.token))
        oMap.token = params.token
      }
      if (typeof params.url === 'string' && params.url.length > 0) {
        setUrl(params.url)
      }
      if (typeof params.id === 'string' && params.id.length > 0) {
        setId(params.id)
      }
      if (typeof params.caid === 'string' && params.caid.length > 0) {
        setCaid(params.caid)
      }
      if (typeof params.mobile === 'string' && params.mobile.length > 0) {
        setMobile(params.mobile)
      }
      if (typeof params.caseid === 'string' && params.caseid.length > 0) {
        setCaseid(params.caseid)
      }
      if (typeof params.key === 'string' && params.key.length > 0) {
        setKey(params.key)
      }
      if (typeof params.title === 'string' && params.title.length > 0) {
        setTitle(params.title)
      }
      if (typeof params.uid === 'string' && params.uid.length > 0) {
        dispatch(setUserInfo({ ...userInfo, id: params.uid }))
        oMap.uid = params.uid
        setR(true)
      }
    }, 0)
  }, [l])
  useEffect(() => {
    if (id !== '' && userInfo.id !== '' && r) {
      initData(id, setData).catch(e => console.error(e))
    }
  }, [id, userInfo, r])
  return (
    <div className={style.container}>
      <div className={style.pdf}>
        {url !== '' && (
          <Document
            loading={(
              <div className={style.loading}>
                <div className={style['loading-inner']}>
                  <SpinLoading style={{ '--size': '48px' }} />
                  <span className={style['loading-text']}>加载中</span>
                </div>
              </div>
            )}
            file={url}
            onLoadSuccess={e => initDoc(e, setNums, setReady, setWidth, setHeight)}
            error='文书生成失败，请点击“重新生成”操作。'
            // options={{ cMapUrl: 'cmaps/', cMapPacked: true }}
          >
            {renderPages(ready, nums, width, height)}
          </Document>
        )}
      </div>
      {
        ready && ['签名授权', '签署中'].includes(status) && (
          <div className={style.btn}>
            <Button block color='primary' onClick={() => {
              setAnimating(true)
              setVisible(true)
            }}>去签名</Button>
            <Button block className={style.reject} onClick={() => {
              wx.miniProgram.redirectTo({ url: `/packageA/pages/CaseConfirmReject/index?id=${id}&caseid=${caseid}` })
            }}>不同意</Button>
          </div>
        )
      }
      {
        data !== null && status === '签署成功' && (
          <div className={style.btn}>
            <Button block disabled>已签署</Button>
          </div>
        )
      }
      {
        data !== null && data.status === '审核不通过' && (
          <div className={style.btn}>
            <Button block disabled>已不同意</Button>
          </div>
        )
      }
      <CSSTransition
        in={animating}
        timeout={500}
        classNames={animating ? 'detail-forward' : 'detail-back'}
        onExited={() => {
          setVisible(false)
        }}
      >
        {visible ? <Sign hide={() => { setAnimating(false) }} id={id} mobile={mobile} caseid={caseid} k={key} title={title} caid={caid} /> : <span />}
      </CSSTransition>
    </div>
  )
}

export default Main
