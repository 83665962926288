import { $freeGet } from '@js/request'
import { OperateDocShcema } from '@component/schema/operateSchema'

export async function getDataRequest (uid: string, token: string, id: string): Promise<OperateDocShcema> {
  const res = await $freeGet(`/api/arbitrator/documentExchange/${id}`, {
    uid
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.data
}
